<template>
    <div>
        <b-card no-body>            
            <b-table
                striped
                hover
                :items="sessions"
                :fields="fields"
                :busy="loading"
                :empty-text="loading ? 'Loading sessions...' : 'No sessions found'"
                small
            >
                <template v-slot:cell(ipAddress)="data">
                    <b-link>{{ data.item.ipAddress }}</b-link>
                </template>

                <template v-slot:cell(userAgent)="data">
                    <b-tooltip :target="'tooltip-' + data.index" triggers="hover" placement="top">
                        {{ data.item.userAgent }}
                    </b-tooltip>
                    <b-link :id="'tooltip-' + data.index">{{ data.item.userAgent }}</b-link>
                </template>

                <template v-slot:cell(loginTime)="data">
                    <span class="text-capitalize">{{ formatDate(data.item.loginTime) }}</span>
                </template>

                <template v-slot:cell(actions)="data">
                    <b-button size="sm" @click="revokeSession(data.item.id)" variant="danger">Revoke</b-button>
                </template>
            </b-table>
        </b-card>        
    </div>
</template>

<script>

import _ from "lodash";
import {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BInputGroupPrepend, 
    BInputGroupAppend, 
    BInputGroup,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip, 
    VBTooltip,
    VBToggle,
    BSpinner,
    BSidebar,
    BFormCheckbox
} from "bootstrap-vue";

import AuthProvider from '@/providers/Auth'
import { formatDate } from "@/@core/utils/filter";
const AuthResource = new AuthProvider()


export default {
    name: 'SettingsSessions',
    components: {
        BCard,
        BCardBody,
        BRow,
        BCol,
        BForm,
        BInputGroupPrepend, 
        BInputGroupAppend, 
        BInputGroup,
        BFormGroup,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTooltip, 
        VBTooltip,
        VBToggle,
        BSpinner,
        BSidebar,
        BFormCheckbox
    },
    data() {
        return {
            sessions: [],
            loading: false,
            error: null,
            fields: [
                { key: 'userName', label: 'Usuario'},
                { key: 'connectionName', label: 'Conexión'},
                { key: 'ipAddress', label: 'IP', },
                { key: 'userAgent', label: 'Agente',},
                { key: 'loginTime', label: 'Fecha Conexión'},
                // { key: 'actions', label: 'Actions' }
            ]
        }
    },
    async mounted() {
        await this.getSessions()
    },
    methods: {
        async getSessions () {
            this.loading = true
            this.error = null
            try {
                const { data } = await AuthResource.getSessions()
                this.sessions = data
            } catch (error) {
                this.error = error
                this.handleResponseErrors(error)
            } finally {
                this.loading = false
            }
        },        
    }
}
</script>